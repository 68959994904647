import { javascript } from '@api/index'
import { clone } from '@avvoka/shared'
import { useHydration } from '@stores/utils'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export type CustomClauseType = ReturnType<typeof createCustomClauseStore>
export const allStores = ref<Array<CustomClauseType>>([])

export type CustomClauseDataType = Backend.Models.CustomClause & {
  for_onboarder_editor: Backend.Models.CustomClause.ForEditor[]
  for_nego_editor: Backend.Models.CustomClause.ForEditor[]
}

export const createCustomClauseStore = (uniqueId: string | number) => {
  const customClauseStore = defineStore('customClause-store-' + String(uniqueId), () => {
    const hydration = useHydration<CustomClauseDataType>(javascript.customClauses)
    const hydrateById = (id: number, fields: Array<keyof CustomClauseDataType> = []) => {
      return hydration.hydrate({ id }, fields)
    }
    allStores.value.push(customClauseStore as unknown as CustomClauseType)

    const onboarderClauses = hydration.hydratedComputed('for_onboarder_editor')
    const negoClauses = hydration.hydratedComputed('for_nego_editor')

    const clauses = computed(() => {
      let clauses: Backend.Models.CustomClause.ForEditor[] = []
      if (hydration.isFieldHydrated('for_onboarder_editor')) {
        clauses = onboarderClauses.value.slice()
      }

      if (hydration.isFieldHydrated('for_nego_editor')) {
        clauses = negoClauses.value.slice()
      }

      for (let i = 0; i < clauses.length; i++) {
        const clause = clauses[i]
        if (clause.default) {
          const clonedClause = clone(clause)
          clonedClause.variant_id += 'default'
          clonedClause.default = false
          clauses.splice(i + 1, 0, clonedClause)
        }
      }

      return clauses
    })

    return {
      ...hydration,
      hydrateById,
      id: hydration.hydratedComputed('id'),
      clauses: clauses,
      clausesById: computed(() => {
        const clausesById: Record<string, Backend.Models.CustomClause.ForEditor[]> = {}
        clauses.value.forEach((clause) => {
          clausesById[clause.custom_clause_id] ??= []
          clausesById[clause.custom_clause_id].push(clause)
        })
        return clausesById
      }),
      updateClause: (variant: Backend.Models.CustomClause.ForEditor) => {
        const clauses = hydration.isFieldHydrated('for_onboarder_editor') ? hydration.hydratedData.value?.for_onboarder_editor ?? [] : hydration.hydratedData.value?.for_nego_editor ?? []
        const index = clauses.findIndex((clause) => clause.variant_id === variant.variant_id && clause.custom_clause_id === variant.custom_clause_id && clause.default === variant.default)
        if (index !== -1) {
          clauses[index] = variant
        }
      }
    }
  })
  return customClauseStore
}

export const useCustomClauseStore = createCustomClauseStore('current')
